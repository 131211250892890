<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">

              <v-col class="flex_end" v-if="$store.state.user_permissions.issue_update">
                <v-btn
                  text
                  @click="user_select_dialog()"
                  :disabled="list.type=='Zakończony'"
                >
                  {{list.assign_user ? 'Opiekun: '+list.assign_user_name : 'Przypisz użytkownika'}}
                </v-btn>
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog=true"
                  v-if="list.type!='Zakończony'"
                >
                  Zamknij zgłoszenie
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Szczegóły zgłoszenia</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <h3>Numer zgłoszenia: {{list.id}}</h3>
                <h3>Nazwa projektu: {{list.project_name}}</h3>

                <p>{{list.description}}</p>

                <div v-if="list.files">
                <div v-for="file in list.files" :key="file.id" style="margin-top: 5px">
                  <v-btn
                    
                    @click="open_file(file.id,file.name)"
                    color="grey"
                    class="white--text"
                  >
                    {{file.name}}
                  </v-btn>
                </div>
              </div>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>


        
        <v-card 
          v-for="comment in list.comments"
          :key="comment.id"
          :class="comment.client ? 'dialog_client':'dialog_author'"
          :color="comment.client ? '':'primary'"
        >
          <v-card-text>
            {{comment.message}}

            <div v-if="comment.files.length">
              <div v-for="file in comment.files" :key="file.id" style="margin-top: 5px">
                <v-btn
                  
                  @click="open_file(file.id,file.name)"
                  color="grey"
                  class="white--text"
                >
                  {{file.name}}
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>


        <div
          v-if="$store.state.user_permissions.issue_comment_create"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <v-card class="card_section" v-if="!disabled && list.type !='Zakończony'">
              <v-card-title>Wyślij odpowiedź</v-card-title>
              <v-card-text>
                <v-textarea
                  v-model="response.text"
                  filled
                  color="primary"
                ></v-textarea>
                <v-file-input
                  v-model="response.files"
                  truncate-length="15"
                  chips
                  multiple
                  label="Załącz pliki"
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="!response.text.length"
                @click="send_response()"
              >
                Wyślij
              </v-btn>
            </v-card-actions>
            </v-card>
          </transition>
        </div>

      </v-col>
    </v-row>

    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz zamknąć wątek?"
      @close="dialog = false;"
      @confirm="close_issue()"
    />
    <v-dialog
      v-model="user_select"
      persistent
    >
      <v-card>
        <v-card-title class="headline primary white--text">
          Przypisz użytkownika
          <v-spacer/>
          <v-btn
            text
            icon
            dark
            @click="user_select = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-2">
          
          <!-- <v-list-item 
            v-for="user in users"
            :key="user.id"
            two-line
            @click="assign_user_to_issue(user.id)"
          >
            <v-list-item-content>
              <v-list-item-title>{{user.name}}</v-list-item-title>
              <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->

          <v-autocomplete
            v-model="selected_user"
            :items="users"
            item-text="name"
            item-value="id"
            no-data-text="Brak wyników"
            label="Lista użytkowników"
            :rules="$store.state.rules.not_null"
          ></v-autocomplete>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="user_select = false"
          >
            Anuluj
          </v-btn>
          <v-btn
            color="primary"
            depressed
            tile
            @click="assign_user_to_issue()"
          >
            Zapisz zmiany
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista zgłoszeń',
        disabled: false,
        to: '/zgloszenia',
      },
      {
        text: 'Zgłoszenie',
        disabled: true,
        to: '/zgloszenie',
      },
    ],
    dialog: false,
    user_select: false,
    disabled: false,

    id: 0,

    response: {
      text: '',
      files: null
    },

    list: {
      type: 'Zakończony',
      comments: []
    },
    selected_user: null,
    users: [],
  }),
  methods: {
    get_data(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/issue/'+this.id, data: {}, method: 'GET' })
        .then(resp => {
          this.list = resp.data;
          // this.attached_files = resp.data.files;
          console.log(resp.data);
          // this.user_data = resp.data.user_assign;
          this.$store.commit('loader_off');
        })
        .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
    },
    // Zmiana opiekuna zgloszenia
    user_select_dialog(){
      this.$store.commit('loader');

      this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        console.log(resp.data);
        this.users = resp.data;
        this.user_select = true;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
    },
    assign_user_to_issue(){
      this.$store.commit('loader');
      
      let formData = new FormData();
      formData.append("assign_user", this.selected_user);

      this.$axios({url: this.$store.state.api +'/issue/'+this.list.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$store.commit("snackbar", {
            text: "Przypisano użytkownika",
            color: "primary",
            btn_color: "white"
          });
          this.get_data();
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })

      this.user_select = false;
    },
    send_response(){
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("message", this.response.text);
      formData.append("issue_id", this.list.id);
      if(this.response.files){
        for(let file in this.response.files){
          formData.append("files["+file+"][file]", this.response.files[file]);
        }
      }

      this.$axios({url: this.$store.state.api +'/issue_comments', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$store.commit("snackbar", {
            text: "Dodano komentarz",
            color: "primary",
            btn_color: "white"
          });

          this.get_data(); 
          this.disabled = true;
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    close_issue(){
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("type", 'Zakończony');

      this.$axios({url: this.$store.state.api +'/issue/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/zgloszenia');
          this.$store.commit("snackbar", {
            text: "Zamknięto wątek",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    open_file(id,file_name){
      this.$store.dispatch('download_file', { id: id,name: file_name });
    }
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    this.get_data();
  }
};
</script>

<style lang="scss" scoped>
.dialog_client {
  margin-top: 25px;
  margin-right: 50px;
}
.dialog_author {
  margin-top: 25px;
  margin-left: 50px;
}
.checkbox {
  margin-top: 0;
}
</style>